import { useParams } from "react-router-dom";
import css from "./styles/ViewUserPage.module.scss";
import React, { useEffect, useState } from "react";
import { useFirebase } from "../contexts/firebaseContext";
import { httpsCallable } from "firebase/functions";
import Navbar from "./Navbar";
import NoProfilePhoto from "../resources/no_profile.png";
import { RELATIONSHIP_STATUSES } from "../consts";
import { logEvent } from "firebase/analytics";

function ViewUserPage() {
    const {netid} = useParams();
    const [person, setPerson] = useState(null);
    const [databaseData, setDatabaseData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const { user, loadingUser, functions, isUsingEmulator, analytics } = useFirebase();

    useEffect(() => {
        async function run() {
            if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
            setError("");
            setLoading(true);
            if(!netid) return;
            const getYalieByNetid = httpsCallable(functions, "getYalieByNetid");
            let result;
            try {
                result = await getYalieByNetid({ netid });
            } catch(e) {
                console.error(e);
                setError(e.message);
                return;
            }
            setPerson(result.data.yaliesJson[0]);
            setDatabaseData(result.data.databaseData);
            setError("");
            setLoading(false);
        }
        run();
    }, [functions, netid, isUsingEmulator]);

    useEffect(() => {
        logEvent(analytics, "user_viewed", {
            netid,
        });
    }, [analytics, netid]);

    if(loadingUser) return null;

    if(!user) {
        window.location.href = "/";
        return null;
    }

    if(!netid) {
        window.location.href = "/";
        return null;
    }

    if(loading) {
        return (
            <>
                <Navbar />
                <div id={css.main_content} className="rails">
                    <h1 id={css.loading} className="gradient_text">Loading...</h1>
                    <p id={css.error}>{error}</p>
                </div>
            </>
        );
    }

    if(!person || !databaseData) {
        return (
            <>
                <Navbar />
                <div id={css.main_content} className="rails">
                    <p id={css.error}>{error}</p>
                </div>
            </>
        );
    }

    const fullName = `${person.first_name} ${person.last_name}`;
    const college = person.college;
    const year = person.year;
    const imageURL = person.image || NoProfilePhoto;
    const status = databaseData.status in RELATIONSHIP_STATUSES && RELATIONSHIP_STATUSES[databaseData.status];
    const statusDisplayText = status ?
        status.renderDisplayText(person.first_name) :
        (
            <span>
                {person.first_name} set their status to: "<span className="gradient_text">{databaseData.status}</span>"
            </span>
        );

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                <p id={css.error}>{error}</p>
                <img id={css.profile_photo} src={imageURL} alt={`${fullName}`} />
                <h1 id={css.title} className="gradient_text">{fullName}</h1>
                <h2 id={css.subtitle}>{college} • {year}</h2>
                <h2 id={css.status}>{statusDisplayText}</h2>
            </div>
        </>
    );
}

export default ViewUserPage;
