import React, { useCallback, useEffect, useMemo, useState } from "react";
import css from "./styles/MatchmakingPage.module.scss";
import { Link } from "react-router-dom";
import Button from "./Button";
import NoProfileImage from "../resources/no_profile.png";
import { faChevronRight, faHome, faSearch, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import MatchmakerProfiles from "./MatchmakerProfiles";
import { useFirebase } from "../contexts/firebaseContext";
import { httpsCallable } from "firebase/functions";
import Input from "./Input";
import { MATCHMAKER_CURRENT_ACTIVE_ROUND, MATCHMAKER_MAX_SELECTIONS } from "../consts";
import { logEvent } from "firebase/analytics";

function ExplainerSlide({ onContinuePress }) {
    return (
        <div id={css.explainer_slide}>
            <h1 id={css.title} className="gradient_text">Single Yalies Matchmaker</h1>
            <div id={css.content}>
                <MatchmakerProfiles selected={[]} fillEmptyWithPlaceholder />
                <ul>
                    <li>Add up to 3 people you're interested in</li>
                    <li>If they add you back, it's a match!</li>
                    <li>Matches will be revealed via email</li>
                </ul>
                <Button
                    large
                    icon={faChevronRight}
                    iconRight
                    onClick={onContinuePress}
                >
                    Let's go
                </Button>
            </div>
        </div>
    )
}

function WrongStatusSlide() {
    return (
        <div id={css.wrong_status_slide}>
            <h1 id={css.title} className="gradient_text">Single Yalies Matchmaker</h1>
            <div id={css.content}>
                <MatchmakerProfiles selected={[]} fillEmptyWithPlaceholder />
                <p>You've indicated you're not in the dating market right now.</p>
                <p>To use matchmaker, please change your status.</p>
                <Button
                    large
                    icon={faUser}
                    onClick={() => window.location.href = "/profile"}
                >
                    Edit profile
                </Button>
            </div>
        </div>
    );
}

function UserResultCard({ user, onClick }) {
    const fullName = `${user.first_name} ${user.last_name}`;
    const imageURL = user.image || NoProfileImage;
    return (
        <div className={css.profile} onClick={onClick}>
            <img src={imageURL} alt={fullName} />
            <p>{fullName}</p>
        </div>
    );
}

function SelectUsersSlide({
    setError,
    matchmakerStatus,
    refreshMatchmakerStatus
}) {
    const { functions, analytics } = useFirebase();
    const [searchText, setSearchText] = useState("");
    const [userResults, setUserResults] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        refreshMatchmakerStatus();
    }, [refreshMatchmakerStatus]);
    
    const performSearch = useCallback(async (e) => {
        e.preventDefault();
        setError("");
        const yaliesSearchFunction = httpsCallable(functions, "yaliesSearch");
        let result;
        try {
            result = await yaliesSearchFunction({ query: searchText });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        setUserResults(result.data);
        logEvent(analytics, "matchmaker_search", {
            search_text: searchText
        });
    }, [analytics, functions, searchText, setError]);

    const searchBar = useMemo(() => (
        <form onSubmit={performSearch} id={css.search_bar}>
            <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Pericles Lewis"
                jumbo
            />
            <Button
                icon={faSearch}
                onClick={performSearch}
                jumbo
                disabled={!searchText}
            />
        </form>
    ), [performSearch, searchText]);

    const onCardClick = useCallback(async (netid) => {
        const addMatchmakerSelection = httpsCallable(functions, "addMatchmakerSelection");
        setError("");
        logEvent(analytics, "matchmaker_select", {
            netid
        });
        try {
            await addMatchmakerSelection({
                round: MATCHMAKER_CURRENT_ACTIVE_ROUND,
                selectedNetid: netid,
            });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        setSearchText("");
        setUserResults([]);
        refreshMatchmakerStatus();
    }, [analytics, functions, refreshMatchmakerStatus, setError]);

    const resetMatchmakerPicks = useCallback(async () => {
        const resetMatchmakerPicks = httpsCallable(functions, "resetMatchmakerPicks");
        setError("");
        logEvent(analytics, "matchmaker_reset");
        try {
            await resetMatchmakerPicks({
                round: MATCHMAKER_CURRENT_ACTIVE_ROUND,
            });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        refreshMatchmakerStatus();
    }, [functions, refreshMatchmakerStatus, setError]);

    const submitMatchmakerPicks = useCallback(async () => {
        setSubmitting(true);
        const submitMatchmakerPicks = httpsCallable(functions, "submitMatchmakerPicks");
        setError("");
        logEvent(analytics, "matchmaker_submit");
        try {
            await submitMatchmakerPicks({
                round: MATCHMAKER_CURRENT_ACTIVE_ROUND,
            });
        } catch(e) {
            console.error(e);
            setError(e.message);
            setSubmitting(false);
            return;
        }
        setSubmitting(false);
        refreshMatchmakerStatus();
    }, [functions, refreshMatchmakerStatus, setError]);

    const userResultCards = userResults.map((user) => (
        <UserResultCard
            key={user.netid}
            user={user}
            onClick={() => onCardClick(user.netid)}
        />
    ));

    if(!matchmakerStatus || submitting) return (
        <div id={css.select_users_slide}>
            <h1 id={css.title} className="gradient_text">Loading...</h1>
        </div>
    );
    const showSearchBar = !matchmakerStatus || !matchmakerStatus.selections || matchmakerStatus.selections.length < MATCHMAKER_MAX_SELECTIONS;
    return (
        <div id={css.select_users_slide}>
            <h1 id={css.title} className="gradient_text">Select Your Crushes</h1>
            <div id={css.content}>
                <MatchmakerProfiles selected={matchmakerStatus?.selections} fillEmptyWithPlaceholder />
                <div id={css.buttons}>
                    <Button
                        secondary
                        icon={faTrash}
                        onClick={resetMatchmakerPicks}
                    >
                        Start over
                    </Button>
                    <Button
                        large
                        icon={faChevronRight}
                        iconRight
                        onClick={submitMatchmakerPicks}
                        disabled={!matchmakerStatus?.selections || matchmakerStatus.selections.length < 1}
                    >
                        Lock it in
                    </Button>
                </div>
                {
                    showSearchBar && (
                        <>
                            {searchBar}
                            <div id={css.user_result_cards}>
                                {userResultCards}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

function SubmittedSlide({ matchmakerStatus }) {
    return (
        <div id={css.submitted_slide}>
            <h1 id={css.title} className="gradient_text">Single Yalies Matchmaker</h1>
            <div id={css.content}>
                <MatchmakerProfiles
                    selected={matchmakerStatus?.selections || []}
                />
                <p>You've submitted your Matchmaker picks!</p>
                <p>Keep an eye on your email in the coming days...</p>
                <Button
                    large
                    iconRight
                    icon={faChevronRight}
                    onClick={() => window.location.href = "/search"}
                >
                    Go back to searching
                </Button>
            </div>
        </div>
    )
}

function MatchmakingPage() {
    const { loadingUser, user, isUsingEmulator, functions } = useFirebase();
    const [slide, setSlide] = useState("explainer");
    const [error, setError] = useState("");
    const [matchmakerStatus, setMatchmakerStatus] = useState(null);

    const dismissExplainerSlide = useCallback(async () => {
        if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
        const checkIfAuthenticatedUserHasStatus = httpsCallable(functions, "checkIfAuthenticatedUserHasStatus");
        let result;
        try {
            result = await checkIfAuthenticatedUserHasStatus();
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        if(result.data.status === "taken" || result.data.status === "not-looking") {
            setSlide("wrong_status");
            return;
        }
        if(result.data.status === "unknown") {
            const continueUrl = encodeURIComponent("/matchmaker");
            window.location.href = `/profile?continue=${continueUrl}`;
            return;
        }
        setSlide("select_users")
    }, [functions, isUsingEmulator]);

    const refreshMatchmakerStatus = useCallback(async () => {
        if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
        const getMatchmakerStatus = httpsCallable(functions, "getMatchmakerStatus");
        let result;
        try {
            result = await getMatchmakerStatus({ round: MATCHMAKER_CURRENT_ACTIVE_ROUND });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        setMatchmakerStatus(result.data || {});
        
        if(result.data?.submittedSelectionsAt) {
            setSlide("submitted");
        }
    }, [functions, isUsingEmulator]);

    const slideElem = useMemo(() => {
        if(slide === "explainer") return (
            <ExplainerSlide onContinuePress={dismissExplainerSlide} />
        );
        if(slide === "wrong_status") return (
            <WrongStatusSlide />
        );
        if(slide === "select_users") return (
            <SelectUsersSlide
                setError={setError}
                matchmakerStatus={matchmakerStatus}
                refreshMatchmakerStatus={refreshMatchmakerStatus}
            />
        );
        if(slide === "submitted") return (
            <SubmittedSlide
                matchmakerStatus={matchmakerStatus}
            />
        );
    }, [dismissExplainerSlide, matchmakerStatus, refreshMatchmakerStatus, slide]);

    if(loadingUser) return null;
    if(!user) {
        window.location.href = "/";
        return null;
    }

    const errorMessage = error && (
        error === "taken" ? (
            <div id={css.taken_message}>
                <h3>Sorry!</h3>
                <p>This person is either taken or not looking for a relationship right now.</p>
            </div>
        ) : (
            <p id={css.error}>{error}</p>
        )
    );

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                {errorMessage}
                {slideElem}
            </div>
        </>
    );
}

export default MatchmakingPage;
