import React from "react";
import css from "./styles/NotFoundPage.module.scss";
import { Link } from "react-router-dom";
import Button from "./Button";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function NotFoundPage() {
    return (
        <div id={css.main_content} className="rails">
            <h1 id={css.title}>404: Page Not Found</h1>
            <Link to="/">
                <Button icon={faHome}>Go Home</Button>
            </Link>
        </div>
    );
}

export default NotFoundPage;
