import Button from "./Button";
import Navbar from "./Navbar";
import css from "./styles/LandingPage.module.scss";
import React, { useCallback } from "react";
import { faChevronRight, faComment, faHeart } from "@fortawesome/free-solid-svg-icons";
import AuthButton from "./AuthButton";
import { logEvent } from "firebase/analytics";
import { useFirebase } from "../contexts/firebaseContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MATCHMAKER_PROMO_BANNER_ENABLED } from "../consts";

function LandingPage() {
    const {analytics} = useFirebase();

    const onSignIn = useCallback(() => {
        logEvent(analytics, "start_button_clicked");
        window.location.href = "/search";
    }, [analytics]);

    const onSignInMatchmaker = useCallback(() => {
        logEvent(analytics, "matchmaker_button_clicked");
        window.location.href = "/matchmaker";
    }, [analytics]);
    
    const promoBanner = MATCHMAKER_PROMO_BANNER_ENABLED && (
        <div id={css.promo_banner}>
            <h3>Introducing: The Matchmaker</h3>
            <p>Select 3 people you like. If one of them chooses you back, you'll both get an email!</p>
            <AuthButton
                icon={faHeart}
                secondary
                onSignIn={onSignInMatchmaker}
            >
                Find your match
            </AuthButton>
        </div>
    );

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                {promoBanner}
                <h1 id={css.hero_text} className="gradient_text">Is my class crush single?</h1>
                <h2 id={css.subhero_text}>Log in with your Yale email to find out.</h2>
                <AuthButton
                    large
                    icon={faChevronRight}
                    iconRight
                    onSignIn={onSignIn}
                >
                    Start Searching
                </AuthButton>
            </div>
        </>
    );
}

export default LandingPage;