import React, { useCallback, useMemo, useState } from "react";
import css from "./styles/EditProfilePage.module.scss";
import { RELATIONSHIP_STATUSES } from "../consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPencil, faX } from "@fortawesome/free-solid-svg-icons";
import { useFirebase } from "../contexts/firebaseContext";
import Navbar from "./Navbar";
import Input from "./Input";
import { httpsCallable } from "firebase/functions";
import { logEvent } from "firebase/analytics";
import Button from "./Button";

function EditProfilePage() {
    const { user, loadingUser, functions, isUsingEmulator, analytics } = useFirebase();
    const [error, setError] = useState("");
    const [doShowCustomStatusPage, setShowCustomStatusPage] = useState(false);
    const [customStatus, setCustomStatus] = useState("");

    const continueUrl = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const param = urlParams.get("continue");
        if(!param) return null;
        return decodeURIComponent(param);
    }, []);

    const setStatus = useCallback(async (status) => {
        if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
        const editUserStatus = httpsCallable(functions, "editUserStatus");
        setError("");
        
        try {
            await editUserStatus({ status });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }

        logEvent(analytics, "user_status_set", {
            status,
        });
        if(continueUrl) {
            window.location.href = continueUrl;
        } else {
            window.location.href = "/search";
        }
    }, [analytics, functions, isUsingEmulator]);

    const showCustomStatusPage = useCallback(() => {
        setShowCustomStatusPage(true);
    }, []);

    const onCustomStatusSave = useCallback(async () => {
        if(customStatus.length === 0) {
            setError("Please enter a custom status.");
            return;
        }
        if(customStatus.length > 200) {
            setError("Your custom status is too long. Max 200 characters.");
            return;
        }
        await setStatus(customStatus);
    }, [customStatus, setStatus]);

    if(loadingUser) return null;
    if(!user) {
        window.location.href = "/";
        return null;
    }

    if(doShowCustomStatusPage) {
        return (
            <>
                <Navbar />
                <div id={css.main_content}>
                    <div>
                        <p id={css.error}>{error}</p>
                        <p>My custom status is:</p>
                        <div id={css.custom_status}>
                            <Input
                                placeholder="It's complicated..."
                                value={customStatus}
                                onChange={(e) => setCustomStatus(e.target.value)}
                                large
                            />
                            <Button
                                large
                                icon={faChevronRight}
                                iconRight
                                onClick={onCustomStatusSave}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const options = Object.keys(RELATIONSHIP_STATUSES).filter(i => !["unknown", "hidden"].includes(i)).map((key) => {
        const status = RELATIONSHIP_STATUSES[key];
        
        const onClick = () => {
            setStatus(key);
        };

        return (
            <button className={css.option} key={key} onClick={onClick}>
                {status.buttonLabel}
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    })
    return (
        <>
            <Navbar />
            <div id={css.main_content}>
                <div>
                    <p id={css.error}>{error}</p>
                    <p>Before you start searching, let us know your relationship status.</p>
                    <h1>I am...</h1>
                    <div id={css.options_container}>
                        {options}
                        <div id={css.extra_buttons}>
                            <Button
                                icon={faX}
                                onClick={() => setStatus("hidden")}
                            >
                                Hide my status
                            </Button>
                            <Button
                                icon={faPencil}
                                onClick={showCustomStatusPage}
                            >
                                Set a custom status
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfilePage;