import { httpsCallable } from "firebase/functions";
import { useFirebase } from "../contexts/firebaseContext";
import Navbar from "./Navbar";
import css from "./styles/StatsPage.module.scss";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Timestamp } from "@firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer, faClock, faComment, faHeart, faPeopleGroup, faPerson } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function StatsPage() {
    const { user, loadingUser, functions, isUsingEmulator } = useFirebase();
    const [error, setError] = useState("");
    const [statsResults, setStatsResults] = useState(null);

    useEffect(() => {
        async function run() {
            if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
            const getUserStats = httpsCallable(functions, "getUserStats");

            let result;
            try {
                result = await getUserStats();
            } catch(e) {
                console.error(e);
                setError(e.message);
                return;
            }
            setStatsResults(result.data);
        }
        run();
    }, [functions, isUsingEmulator]);

    if(loadingUser) return null;
    if(!user) {
        window.location.href = "/";
        return null;
    }

    if(!statsResults) {
        return (
            <>
                <Navbar />
                <div id={css.main_content} className="rails">
                    <h1 id={css.title} className="gradient_text">Crunching the numbers...</h1>
                    <p id={css.error}>{error}</p>
                </div>
            </>
        )
    }

    const {
        viewsFromMeCount,
        totalViewsFromOthersCount,
        distinctViewersCount,
        viewCountOfUserWithMostViews,
    } = statsResults;
    const lastViewed = statsResults.lastViewed ? moment(statsResults.lastViewed).fromNow() : "";

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                <h1 id={css.title} className="gradient_text">My statistics</h1>
                <p className={css.stat}>
                    <FontAwesomeIcon icon={faPeopleGroup} />
                    <span className="gradient_text">{distinctViewersCount} distinct {distinctViewersCount === 1 ? "person" : "people"} </span>
                    wanted to know if you're single.
                </p>
                <p className={css.stat}>
                    <FontAwesomeIcon icon={faArrowPointer} />
                    Your profile was clicked 
                    <span className="gradient_text"> {totalViewsFromOthersCount} time{totalViewsFromOthersCount === 1 ? "" : "s"} </span>
                    total by other people.
                </p>
                {
                    viewCountOfUserWithMostViews > 1 &&
                    <>
                        <p className={css.stat}>
                            <FontAwesomeIcon icon={faHeart} />
                            One special person viewed your profile 
                            <span className="gradient_text"> {viewCountOfUserWithMostViews} time{viewCountOfUserWithMostViews === 1 ? "" : "s"}</span>
                            .
                            {
                                viewCountOfUserWithMostViews >= 3 && " Wowza!"
                            }
                        </p>
                    </>
                }
                {
                    lastViewed && (
                        <p className={css.stat}>
                            <FontAwesomeIcon icon={faClock} />
                            The last time your profile was viewed by another person was
                            <span className="gradient_text"> {lastViewed}</span>
                            .
                        </p>
                    )
                }
                <p className={css.stat}>
                    <FontAwesomeIcon icon={faPerson} />
                    You viewed your own profile
                    <span className="gradient_text"> {viewsFromMeCount} time{viewsFromMeCount === 1 ? "" : "s"}</span>
                    .
                    {
                        viewsFromMeCount >= 3 && " Really desperate, huh?"
                    }
                </p>
                <Link to={"/social"} className="nostyle">
                    <p id={css.social_link}>
                        <FontAwesomeIcon icon={faComment} />
                        What people on Fizz are saying
                    </p>
                </Link>
            </div>
        </>
    );
}

export default StatsPage;
