import React from "react";
import NoProfileImage from "../resources/no_profile.png";
import MatchmakerUnknownImage from "../resources/matchmaker_unknown.png";
import css from "./styles/MatchmakerProfiles.module.scss";
import { MATCHMAKER_MAX_SELECTIONS } from "../consts";

function MatchmakerProfiles({ selected, fillEmptyWithPlaceholder }) {
    const selectedUsers = selected || [];
    const elems = selectedUsers.map((user, idx) => {
        const imageURL = user.image || NoProfileImage;
        return (
            <div className={css.profile} key={idx}>
                <img src={imageURL} alt={user.name} />
                <p>{user.name}</p>
            </div>
        );
    });
    if(fillEmptyWithPlaceholder) {
        for(let i = elems.length; i < MATCHMAKER_MAX_SELECTIONS; i++) {
            elems.push(
                <div className={css.profile} key={i}>
                    <img src={MatchmakerUnknownImage} alt="No profile" />
                    <p />
                </div>
            );
        }
    }
    return (
        <div className={css.matchmaker_profiles}>
            {elems}
        </div>
    );
}

export default MatchmakerProfiles;
