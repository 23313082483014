import Navbar from "./Navbar";
import css from "./styles/YaleOnlyPage.module.scss";
import React from "react";

function YaleOnlyPage() {
    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                <h1 id={css.title} className="gradient_text">Yalies only.</h1>
                <p id={css.explainer}>Please sign out, and then sign in with your Yale Google account to use this site.</p>
            </div>
        </>
    );
}

export default YaleOnlyPage;
