import { faChevronRight, faHeart, faSearch, faZ } from "@fortawesome/free-solid-svg-icons";
import { useFirebase } from "../contexts/firebaseContext";
import Button from "./Button";
import Input from "./Input";
import Navbar from "./Navbar";
import css from "./styles/SearchPage.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import NoProfilePhoto from "../resources/no_profile.png";
import { MATCHMAKER_PROMO_BANNER_ENABLED, MOBILE_WIDTH } from "../consts";
import { httpsCallable } from "firebase/functions";
import { logEvent } from "firebase/analytics";

function SearchCard({ person }) {
    const fullName = `${person.first_name} ${person.last_name}`;
    const imageURL = person.image || NoProfilePhoto;
    return (
        <a className="nostyle" href={`/view/${person.netid}`}>
            <div className={css.search_card}>
                <div className={css.left_align}>
                    <img src={imageURL} alt={`${fullName}`} />
                    <div className={css.text_content}>
                        <p className={`gradient_text ${css.full_name}`}>{fullName}</p>
                        <p className={css.college}>{person.college}</p>
                        <p className={css.class_year}>{person.year}</p>
                    </div>
                </div>
                <Button
                    icon={faChevronRight}
                    iconRight
                >
                    {
                        window.screen.width > MOBILE_WIDTH && "Are they single?"
                    }
                </Button>
            </div>
        </a>
    );
}

function SearchPage() {
    const { user, loadingUser, functions, isUsingEmulator, analytics } = useFirebase();
    const [searchText, setSearchText] = useState("");
    const [ranStatusCheckEffect, setRanStatusCheckEffect] = useState(false);
    const [error, setError] = useState("");
    const [userResults, setUserResults] = useState([]);

    useEffect(() => { // Redirect if user doesn't have a status yet
        async function run() {
            if(process.env.NODE_ENV === "development" && !isUsingEmulator) return;
            const checkIfAuthenticatedUserHasStatus = httpsCallable(functions, "checkIfAuthenticatedUserHasStatus");
            let result;
            try {
                result = await checkIfAuthenticatedUserHasStatus();
            } catch(e) {
                console.error(e);
                setError(e.message);
                return;
            }
            if(result.data.status === "unknown") {
                window.location.href = "/profile";
                return;
            }
            setRanStatusCheckEffect(true);
        }
        run();
    }, [functions, isUsingEmulator]);

    const performSearch = useCallback(async (e) => {
        e.preventDefault();
        const yaliesSearchFunction = httpsCallable(functions, "yaliesSearch");
        let result;
        try {
            result = await yaliesSearchFunction({ query: searchText });
        } catch(e) {
            console.error(e);
            setError(e.message);
            return;
        }
        setUserResults(result.data);

        logEvent(analytics, "search_performed", {
            search_text: searchText, 
        });
    }, [analytics, functions, searchText]);

    if(loadingUser) return null;
    if(!user) {
        window.location.href = "/";
        return null;
    }

    if(!ranStatusCheckEffect) {
        return (
            <>
                <Navbar />
                <div id={css.main_content} className="rails">
                    <h1 id={css.title} className="gradient_text">Loading...</h1>
                    <p id={css.error}>{error}</p>
                </div>
            </>
        )
    }

    const userCards = userResults.map((person) => (
        <SearchCard key={person.netid} person={person} />
    ));

    const promoBanner = MATCHMAKER_PROMO_BANNER_ENABLED && (
        <div id={css.promo_banner}>
            <h3>Introducing: The Matchmaker</h3>
            <p>Select 3 people you like. If one of them chooses you back, you'll both get an email!</p>
            <Button
                icon={faHeart}
                onClick={() => window.location.href = "/matchmaker"}
                secondary
            >
                Find your match
            </Button>
        </div>
    );

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                {promoBanner}
                <h1 id={css.title} className="gradient_text">Who's your class crush?</h1>
                <p id={css.error}>{error}</p>
                <form onSubmit={performSearch} id={css.search_bar}>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Peter Salovey"
                        jumbo
                    />
                    <Button
                        icon={faSearch}
                        onClick={performSearch}
                        jumbo
                        disabled={!searchText}
                    />
                </form>
                {userCards}
            </div>
        </>
    );
}

export default SearchPage;
