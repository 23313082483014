import Img01 from "./01.png";
import Img02 from "./02.png";
import Img03 from "./03.png";
import Img04 from "./04.png";
import Img05 from "./05.png";
import Img06 from "./06.png";
import Img07 from "./07.png";
import Img08 from "./08.png";
import Img09 from "./09.png";
import Img10 from "./10.png";
import Img11 from "./11.png";
import Img12 from "./12.png";
import Img13 from "./13.png";
import Img14 from "./14.png";
import Img15 from "./15.png";
import Img16 from "./16.png";
import Img17 from "./17.png";
import Img18 from "./18.png";
import Img19 from "./19.png";
import Img20 from "./20.png";
import Img21 from "./21.png";
import Img22 from "./22.png";
import Img23 from "./23.png";
import Img24 from "./24.png";
import Img25 from "./25.png";
import Img26 from "./26.png";
import Img27 from "./27.png";
import Img28 from "./28.png";
import Img29 from "./29.png";
import Img30 from "./30.png";
import Img31 from "./31.png";
import Img32 from "./32.png";
import Img33 from "./33.png";
import Img34 from "./34.png";
import Img35 from "./35.png";
import Img36 from "./36.png";
import Img37 from "./37.png";
import Img38 from "./38.png";
import Img39 from "./39.png";
import Img40 from "./40.png";
import Img41 from "./41.png";
import Img42 from "./42.png";
import Img43 from "./43.png";
import Img44 from "./44.png";
import Img45 from "./45.png";
import Img46 from "./46.png";
import Img47 from "./47.png";
import Img48 from "./48.png";
import Img49 from "./49.png";
import Img50 from "./50.png";
import Img51 from "./51.png";
import Img52 from "./52.png";
import Img53 from "./53.png";
import Img54 from "./54.png";

const SOCIAL_IMAGES = {
    Img01,
    Img02,
    Img03,
    Img04,
    Img05,
    Img06,
    Img07,
    Img08,
    Img09,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Img21,
    Img22,
    Img23,
    Img24,
    Img25,
    Img26,
    Img27,
    Img28,
    Img29,
    Img30,
    Img31,
    Img32,
    Img33,
    Img34,
    Img35,
    Img36,
    Img37,
    Img38,
    Img39,
    Img40,
    Img41,
    Img42,
    Img43,
    Img44,
    Img45,
    Img46,
    Img47,
    Img48,
    Img49,
    Img50,
    Img51,
    Img52,
    Img53,
    Img54,
};

export default SOCIAL_IMAGES;
