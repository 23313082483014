import React, { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { FIREBASE_CONFIG } from "../consts";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const FirebaseContext = createContext();

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export function FirebaseContextProvider ({ children }) {
    const [loadingUser, setLoadingUser] = useState(true);
    const [user, setUser] = useState(null);
    const [isUsingEmulator, setUsingEmulator] = useState(false);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoadingUser(false);
        });

        if(process.env.NODE_ENV === "development") {
            connectFunctionsEmulator(functions, "localhost", 5001);
            setUsingEmulator(true);
        }
    }, []);
    return (
        <FirebaseContext.Provider value={{
            firebase: app,
            isUsingEmulator,
            auth,
            functions,
            analytics,
            user,
            loadingUser,
        }}>
            {children}
        </FirebaseContext.Provider>
    )
}

export const useFirebase = () => {
    return useContext(FirebaseContext);
}
