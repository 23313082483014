import React from "react";
import css from "./styles/SocialPostsPage.module.scss";
import SOCIAL_IMAGES from "../resources/social_posts/socialPosts";
function SocialPostsPage() {
    return (
        <div id={css.main_content} className="rails">
            <h1 id={css.title}>What People Are Saying</h1>
            <img src={SOCIAL_IMAGES.Img01} alt="what do I put on single yalies if I just want someone to acknowledge my existence and validate me?" />
            <p>Go out and make friends, everyone needs more friends!</p>
            <img src={SOCIAL_IMAGES.Img02} alt="are ppl actually doing single yalies... I'm curious what it is ( but am not single lol)" />
            <p>1500+ users signed up on launch day. You're not alone.</p>
            <img src={SOCIAL_IMAGES.Img03} alt="Logging onto single yalies just to mark that I am currently in a relationship feels kinda great, wishing the same for all of you out there" />
            <p>We're here to make that the case for everyone at Yale!</p>
            <img src={SOCIAL_IMAGES.Img04} alt="Shoutout to single Yalies for making me realize I was a side hoe and that the man I was seeing is not in fact single" />
            <p>Dump his ahh</p>
            <img src={SOCIAL_IMAGES.Img05} alt="is singleyalies a real app or phishing bait" />
            <p>What you see before your eyes is real.</p>
            <img src={SOCIAL_IMAGES.Img06} alt="I wish you could see people's first names who searched you on Single Yalies" />
            <p>Can't expose people like that!!</p>
            <img src={SOCIAL_IMAGES.Img07} alt="can someone start a singleyalies but for friends (im a desperate loner)" />
            <p>Join a club! Make a hit app so people will start liking you! (cries)</p>
            <img src={SOCIAL_IMAGES.Img08} alt="(Poll) People who've hidden their status on singleyalies, why did you do so?" />
            <p>They're creatures of the darkness.</p>
            <img src={SOCIAL_IMAGES.Img09} alt="petition for single yalies to make a 'i think you're hot' button" />
            <p>I think the button is called "talking to them".</p>
            <img src={SOCIAL_IMAGES.Img10} alt="Single Yalies should have an option for users to either stay anonymous or to display their name in the person's stats" />
            <p>The onus is on YOU to actually tell your crush you like them.</p>
            <img src={SOCIAL_IMAGES.Img11} alt="Petition for Single Yalies to make a parody of 'Single Ladies'" />
            <p>Up in the the Buttery, lookin' for some love...</p>
            <img src={SOCIAL_IMAGES.Img12} alt="Me when I get a notification that someone looked me up on Singel Yalies" />
            <p>It's like a little dopamine hit!</p>
            <img src={SOCIAL_IMAGES.Img13} alt="i wish the guy i searched up on single yalies knew i searched him up becasue i want to drop non-verbal hints and that would be the best way (i don't know if he's gay so it would be awkward to ask irl)" />
            <p>He'd be flattered either way. Shoot your shot!</p>
            <img src={SOCIAL_IMAGES.Img14} alt="I have one profile view on single Yalies and I'm confused because I didn't think people knew who I was... WHO WAS IT" />
            <p>A lover? Your suitemate? Eli Yale? Hopefully the first one.</p>
            <img src={SOCIAL_IMAGES.Img15} alt="Oh yeah I feel you, that's the WORST thing that could possibly happen to you" />
            <p>The ABSOLUTE WORST!</p>
            <img src={SOCIAL_IMAGES.Img16} alt="😔" />
            <p>Happens to the best of us.</p>
            <img src={SOCIAL_IMAGES.Img17} alt="Can single Yalies also put sexual orientation for those comfortable to share, like pretty please" />
            <p>Was genuinely thinking about it, but that data is so sensitive, and if leaked, could cause disaster. Guess you'll have to fine-tune your gaydar instead.</p>
            <img src={SOCIAL_IMAGES.Img18} alt="living in fear that the single yalies searches will be published and I will die" />
            <p>Dw bro, if I do that, I'd be exposing myself</p>
            <img src={SOCIAL_IMAGES.Img19} alt="Can someone explain the 'one special person viewed your profile' stat @devs?" />
            <p>Pretty simple. Out of all the people who viewed your profile, one person clicked on you the most times. This is how many times they clicked.</p>
            <img src={SOCIAL_IMAGES.Img20} alt="ok but how do I still get no bitches???" />
            <p>I'm sure you're the rizzler, the rizzly bear, the rizz carlton, the rizz cracker,</p>
            <img src={SOCIAL_IMAGES.Img21} alt="(Poll) how many people ahve looked at your profile on single yalies?" />
            <p>ripperonis to all my homies</p>
            <img src={SOCIAL_IMAGES.Img22} alt="fuck i'm so glad i didn't sign up for it, i'd be shitting bricks rn" />
            <p>OP trying to create chaos</p>
            <img src={SOCIAL_IMAGES.Img23} alt="Ya maybe could have thought to warn us it sends an email to the person you search..." />
            <p>That's part of the fun!</p>
            <img src={SOCIAL_IMAGES.Img24} alt="Huh? What is single yalies?" />
            <p>Haven't heard of the hottest new website in town?</p>
            <img src={SOCIAL_IMAGES.Img25} alt="just looked up my bf on single yalies and he already put down that he's taken" />
            <p>Cute. *cries in corner*</p>
            <img src={SOCIAL_IMAGES.Img26} alt="just looked up my gf on single yalies and she already put down that she's taken (I'm not her boyfriend)" />
            <p>sob</p>
            <img src={SOCIAL_IMAGES.Img27} alt="taken by his other gf" />
            <p>oop—</p>
            <img src={SOCIAL_IMAGES.Img28} alt="Me when I get the email saying someone searched me up on Yalies // Me when I realize it was my friend" />
            <p>Friends are curious, what can I say...</p>
            <img src={SOCIAL_IMAGES.Img29} alt="Cs bro resume padding" />
            <p>Nahhhh</p>
            <img src={SOCIAL_IMAGES.Img30} alt="This valentines-day first-year cs prject is not Yalies. Yalies does not store or sahre search information." />
            <p>Yall ate it up tho...</p>
            <img src={SOCIAL_IMAGES.Img31} alt="I'm looking up everyone I know on Singles Yalies just so they think they have a secret admirer" />
            <p>Chaotic Evil</p>
            <img src={SOCIAL_IMAGES.Img32} alt="(Poll) Have you posted your status on single yalies?" />
            <p>Wonderful, the more statuses the better...</p>
            <img src={SOCIAL_IMAGES.Img33} alt="Someone actually did! The general marketing email says 'Find your class crush.'" />
            <p>Yes, marketing emails went out. But, if you got a second email saying someone looked you up, it's true!</p>
            <img src={SOCIAL_IMAGES.Img34} alt="casually changed my single yalies status to looking for something casual..." />
            <p>"It's Complicated"</p>
            <img src={SOCIAL_IMAGES.Img35} alt="Had my friend search me on single yalies to see if I'd get a notification email, and I didn't. I suppose it either takes a while, or I'll never know if someone searched me..." />
            <p>You'll only get one notification email (the first time anyone looks you up). But, if you've already set your status, you won't get the email. Check your Stats page instead.</p>
            <img src={SOCIAL_IMAGES.Img36} alt="when your crush is single on single yalies" />
            <p>We love to hear it</p>
            <img src={SOCIAL_IMAGES.Img37} alt="How do we delete profile SingleYalies?" />
            <p>Set your status to hidden.</p>
            <img src={SOCIAL_IMAGES.Img38} alt="Can single Yalies make a tab to search by status? What I really need is to be able to locate the other 'want a committed relationship' people. Soooo can I get a Yalie dating site already or what." />
            <p>Yale, give me money to make a dating site plz</p>
            <img src={SOCIAL_IMAGES.Img39} alt="Please make this feature, SingleYalies team!" />
            <p>Noo, don't go 🥺🥺🥺</p>
            <img src={SOCIAL_IMAGES.Img40} alt="Me except how do I remove what I listed for myself and have no relationship status. we should be able to delete our account" />
            <p>Set your status to hidden.</p>
            <img src={SOCIAL_IMAGES.Img41} alt="Are they paying yall by the post or something the glazing is crazy" />
            <p>Nah, I only make one post... the rest is 100% organic, all natural, grass fed babey</p>
            <img src={SOCIAL_IMAGES.Img42} alt="Did everyone get an email from single yalies at exactly 11:30 saying that someone viewed them because I'm super suspicious??" />
            <p>I wouldn't falsely get your hopes up like that! It was someone real.</p>
            <img src={SOCIAL_IMAGES.Img43} alt="Least obvious astroturfing attempt by the single yalies dev team" />
            <p>Nah, all organic. Yalies just really like the website.</p>
            <img src={SOCIAL_IMAGES.Img44} alt="I just looked up my bf on single Yalies and he put 'looking for something casual' how fucked am I???" />
            <p>uhh... time to talk to him...</p>
            <img src={SOCIAL_IMAGES.Img45} alt="got an email saying someone searched for me on single yalies.. Are they sending them to everyone so we add our info or is it actually cause someone searched me?" />
            <p>It's real. Go get em.</p>
            <img src={SOCIAL_IMAGES.Img46} alt="Me in you" />
            <p>...</p>
            <img src={SOCIAL_IMAGES.Img47} alt="who the fuck searched me up on single yalies" />
            <p>Wasn't me...</p>
            <img src={SOCIAL_IMAGES.Img48} alt="did everyone get an email from single yalies at 5:49 pm?" />
            <p>Marketing emails went out for frosh around 10:30, and for everyone else around noon. Anything after that is a legit profile view</p>
            <img src={SOCIAL_IMAGES.Img49} alt="Huh? What is single yalies?" />
            <p>You know it!</p>
            <img src={SOCIAL_IMAGES.Img50} alt="the biggest marketing scheme of 2024" />
            <p>Make friends at formal!</p>
            <img src={SOCIAL_IMAGES.Img51} alt="can someone start a singleyalies but for friends (im a desperate loner)" />
            <p>fr take CS50 and learn how to make websites!</p>
            <img src={SOCIAL_IMAGES.Img52} alt="UPDATE: found my chronically online friend looked me up to see what was up w the website :(" />
            <p>:((</p>
            <img src={SOCIAL_IMAGES.Img53} alt="Nobody checked my profile holy shit. It's over girlies" />
            <p>It's never over, girlypop.</p>
            <img src={SOCIAL_IMAGES.Img54} alt="Single yalies is too dangerous" />
            <p>Dangerous indeed. But every great website comes with danger, doesn't it?</p>
        </div>
    );
}

export default SocialPostsPage;
