import EditProfilePage from "../components/EditProfilePage";
import LandingPage from "../components/LandingPage";
import NotFoundPage from "../components/NotFoundPage";
import SearchPage from "../components/SearchPage";
import ViewUserPage from "../components/ViewUserPage";
import YaleOnlyPage from "../components/YaleOnlyPage";
import StatsPage from "../components/StatsPage";
import SocialPostsPage from "../components/SocialPostsPage";
import MatchmakingPage from "../components/MatchmakingPage";

const ROUTER_CONFIG = [
    {
        path: "*",
        element: <NotFoundPage />
    },
    {
        path: "/",
        element: <LandingPage />,
    },
    {
        path: "/search",
        element: <SearchPage />,
    },
    {
        path: "/disallowed",
        element: <YaleOnlyPage />,
    },
    {
        path: "/view/:netid",
        element: <ViewUserPage />,
    },
    {
        path: "/profile",
        element: <EditProfilePage />,
    },
    {
        path: "/stats",
        element: <StatsPage />,
    },
    {
        path: "/social",
        element: <SocialPostsPage />,
    },
    {
        path: "/matchmaker",
        element: <MatchmakingPage />,
    },
];

export default ROUTER_CONFIG;
